
export default class URLConstants {

    //BASE URLs
    static GAUS_BASE_URL: string =
        process.env.GAUS_BASE_URL ||
        "https://1crpomkpe9.execute-api.ap-south-1.amazonaws.com/prod/d360-gaus";

      

    static GSNS_BASE_URL: string =
    process.env.GSNS_BASE_URL ||
    "https://1crpomkpe9.execute-api.ap-south-1.amazonaws.com/prod/gsns"

    static GABS_BASE_URL: string =
    process.env.GABS_BASE_URL ||
    "https://1crpomkpe9.execute-api.ap-south-1.amazonaws.com/prod/gabs"

    static GARP_BASE_URL: string =
    process.env.GARP_BASE_URL ||
    "https://1crpomkpe9.execute-api.ap-south-1.amazonaws.com/prod/d360-garp"

    static GAAS_BASE_URL: string =
    process.env.GAAS_BASE_URL ||
    "https://1crpomkpe9.execute-api.ap-south-1.amazonaws.com/prod/d360-gaas"

    static GCNS_BASE_URL: string =
    process.env.GCNS_BASE_URL ||
    "https://1crpomkpe9.execute-api.ap-south-1.amazonaws.com/prod/gcns"

    static GAMS_BASE_URL: string =
    process.env.GAMS_BASE_URL ||
    "https://api.telinfy.net/gams"
   

    static G_360_BASE_URL: string =
    process.env.G_360_BASE_URL ||
    "https://api.telinfy.net/g360";

    static CAMPAIGN_CONTACT_URL: string =
    process.env.CAMPAIGN_CONTACT_URL ||
    "https://s3.ap-south-1.amazonaws.com/telinfy.greenadsglobal.com/templates/whatsapp/general/campaign-contacts.xlsx";

    static LOGIN_BASE_URL: string = URLConstants.GAUS_BASE_URL + "/login";
    static SIGNUP_BASE_URL: string = URLConstants.GAUS_BASE_URL + "/signup";
    static RESET_PASSWORD_BASE_URL: string = URLConstants.GAUS_BASE_URL + "/reset/password";
    static USER_NAME_VERIFICATION_BASE_URL: string = URLConstants.GAUS_BASE_URL + "/forgot/password/otp";
    static RESET_FORGOT_PASSWORD_BASE_URL: string = URLConstants.GAUS_BASE_URL + "/reset/password/otp";
    static USER_BASE_URL: string = URLConstants.GAUS_BASE_URL + "/users/me";
    static CREATE_USER_BASE_URL: string = URLConstants.GAUS_BASE_URL + "/users";
    static ALL_USER_BASE_URL: string = URLConstants.GAUS_BASE_URL + "/accounts/me/users";
    static ADD_CONTACTFILE_T0_GROUP_BASE_URL:string = URLConstants.GARP_BASE_URL + "/users/me/contact-groups/contacts";
    static ADD_CONTACT_BASE_URL: string = URLConstants.GARP_BASE_URL + "/users/me/contacts";
    static DELETE_CONTACT_BASE_URL: string = URLConstants.GARP_BASE_URL + "/users/me/contact/delete";
    static BLOCK_CONTACT_BASE_URL: string = URLConstants.GARP_BASE_URL + "/users/me/contact";
    static BLOCK_CUSTOMER_BY_AGENT_BASE_URL: string = URLConstants.G_360_BASE_URL + "/conversation";
    static GET_BLOCK_CONTACT_BASE_URL: string = URLConstants.GARP_BASE_URL + "/users/contacts/blocked-contacts";
    static DIRECTLY_BLOCK_CONTACT_BASE_URL: string = URLConstants.GARP_BASE_URL + "/users/me/create-contact/block";
    static ALL_CONTACTS_BASE_URL: string = URLConstants.GARP_BASE_URL + "/users/me/contacts";
    static ADD_CONTACT_GROUP_BASE_URL: string = URLConstants.GARP_BASE_URL + "/users/me/contact-groups";
    static ALL_CONTACT_GROUP_BASE_URL: string = URLConstants.GARP_BASE_URL + "/users/me/contact-groups";
    static CREATE_WB_ACCOUNT_BASE_URL: string = URLConstants.G_360_BASE_URL + "/whatsapp-business/accounts";
    static UPDATE_WB_ACCOUNT_BASE_URL: string = URLConstants.G_360_BASE_URL + "/whatsapp-business/";
    static TEAMS_OF_ACCOUNT_BASE_URL: string = URLConstants.GAUS_BASE_URL + "/account/me/teams";
    static CREATE_TEAM_BASE_URL: string = URLConstants.GAUS_BASE_URL + "/account/teams";
    static USER_OF_TEAM_BASE_URL: string = URLConstants.GAUS_BASE_URL + "/team/:teamId/users";
    static GET_TEMPLATES_BASE_URL: string = URLConstants.G_360_BASE_URL + "/adapter/whatsapp/templates";
    static GET_WB_ACCOUNT_BASE_URL: string = URLConstants.G_360_BASE_URL + "/whatsapp-business/accounts";
    static SEARCH_CONTACTS_BASE_URL: string = URLConstants.GARP_BASE_URL + "/users/me/contact/search/";
    static ALL_CAMPAIGN_BASE_URL: string = URLConstants.G_360_BASE_URL + "/whatsapp/all/campaign";
    static ACCOUNT_STATS_BASE_URL: string = URLConstants.GAAS_BASE_URL + "/accounts/me/stats";
    static VALIDATE_CONTACT_BASE_URL: string = URLConstants.G_360_BASE_URL + "/whatsapp/contact/validate";
    static EDIT_CONTACT_BASE_URL: string = URLConstants.GARP_BASE_URL + "/users/me/contact";
    static EDIT_PROFILE_BASE_URL: string = URLConstants.GAUS_BASE_URL + "/user/me";
    static CONTACT_GROUP_BASE_URL: string = URLConstants.GARP_BASE_URL + "/user/me/contact-groups";
    static DELETE_GROUP_BASE_URL: string = URLConstants.GARP_BASE_URL + "/users/me/contact-groups/group/delete";
    static DELETE_CONTACT_IN_GROUP_BASE_URL: string = URLConstants.GARP_BASE_URL + "/users/me/contact-groups";
    static TEMPLATE_DELETE_BASE_URL: string = URLConstants.G_360_BASE_URL + "/adapter/d360/template";
    static GOLD_RATE_BASE_URL: string = URLConstants.G_360_BASE_URL + "/whatsapp-business/accounts/chatbot-config/";

    static ALL_NOTES_BASE_URL: string = URLConstants.G_360_BASE_URL + "/conversation/note";
    static ADD_NOTES_BASE_URL: string = URLConstants.G_360_BASE_URL + "/conversation/note";
    static DELETE_NOTES_BASE_URL: string = URLConstants.G_360_BASE_URL + "/conversation/note/delete";
    static CHAT_LIST_BASE_URL: string = URLConstants.G_360_BASE_URL + "/whatsapp/conversations";
    static CHAT_MESSAGES_BASE_URL: string = URLConstants.G_360_BASE_URL + "/conversation/messages";
    static DIRECT_MESSAGES_BASE_URL: string = URLConstants.G_360_BASE_URL + "/whatsapp/message/direct";
    static CONVERSATION_UPDATE_BASE_URL: string = URLConstants.G_360_BASE_URL + "/conversation/agent";
    static CONVERSATION_UNBLOCK_BASE_URL: string = URLConstants.G_360_BASE_URL + "/conversation/customer-unblock";
    static CONVERSATION_TAGS_BASE_URL: string = URLConstants.G_360_BASE_URL + "/account/conversation/tag/fetch";
    static All_TAG_BASE_URL: string = URLConstants.G_360_BASE_URL + "/account/tag/fetch";
    static CREATE_TAG_BASE_URL: string = URLConstants.G_360_BASE_URL + "/account/tag/add";
    static ASSIGN_TAG_BASE_URL: string = URLConstants.G_360_BASE_URL + "/account/conversation/tag";
    static REMOVE_TAG_BASE_URL: string = URLConstants.G_360_BASE_URL + "/account/conversation/tag/delete";
    static DELETE_TAG_BASE_URL: string = URLConstants.G_360_BASE_URL + "/account/tag";
    static CHAT_LIST_BY_TAG_BASE_URL: string = URLConstants.G_360_BASE_URL + "/whatsapp/conversations/tags/assigned";
    static CONFIGURE_WEBHOOK_BASE_URL: string = URLConstants.G_360_BASE_URL + "/whatsapp-business/accounts/webhook";
    static DOWNLOAD_ANALYTICS_BASE_URL: string = URLConstants.GAAS_BASE_URL + "/accounts/me/messages/report";
    static DOWNLOAD_CAMPAIGN_REPORT_BASE_URL: string = URLConstants.GAAS_BASE_URL + "/accounts/me/campaigns/report";
    static DOWNLOAD_CHAT_BASE_URL: string = URLConstants.GAAS_BASE_URL + "/accounts/me/conversationmessages";
    static TEMPLATE_MASTER_DATA_BASE_URL: string = URLConstants.G_360_BASE_URL + "/adapter/d360/templates/configs";
    static CREATE_TEMPLATE_BASE_URL: string = URLConstants.G_360_BASE_URL + "/adapter/d360/template";
    static API_KEY_BASE_URL: string = URLConstants.GAUS_BASE_URL + "/users/me/api-key";
    static BLOCK_API_KEY_BASE_URL: string = URLConstants.GAUS_BASE_URL + "/users/me/api-key/";
    static QUICK_REPLY_BASE_URL: string = URLConstants.G_360_BASE_URL + "/whatsapp-business/accounts/quick-reply";
    static QUICK_REPLY_ENABLE_BASE_URL: string = URLConstants.G_360_BASE_URL + "/whatsapp-business/accounts/quickreply";
    static USER_EDIT_BASE_URL: string = URLConstants.GAUS_BASE_URL + "/account/additionalusers/update";
    static USER_DELETE_BASE_URL: string = URLConstants.GAUS_BASE_URL + "/account/additionalusers";
    static DOWNLOAD_CHATBOT_REPORT_BASE_URL: string = URLConstants.GAAS_BASE_URL + "/chatbot";
    static CHATBOT_CONFIGURATIONS_LIST_BASE_URL: string = URLConstants.G_360_BASE_URL + "/whatsapp-business/accounts/chatbot-configs";
    static CREATE_CHATBOT_CONFIGURATIONS_BASE_URL: string = URLConstants.G_360_BASE_URL + "/whatsapp-business/accounts/chatbot-config";
    static EDIT_CHATBOT_CONFIGURATIONS_BASE_URL: string = URLConstants.G_360_BASE_URL + "/whatsapp-business/accounts/chatbot-config/";
    static DOWNLOAD_CHATBOT_REPORT_BHIMA_BASE_URL: string = URLConstants.GAAS_BASE_URL + "/bhima/chatbot";

    

  
  //GAMS URLs
  static SAVE_MEDIA_BASE_URL: string = URLConstants.GAMS_BASE_URL + "/adapter/files/save";

  //TODO
  static UPLOAD_FILE_BASE_URL: string = "https://fs.telinfy.net/adapter/files/upload";

  static SEND_MESSAGE_BASE_URL: string = URLConstants.G_360_BASE_URL + "/whatsapp/templates/message";
  static SEND_CAMPAIGN_BASE_URL: string = URLConstants.G_360_BASE_URL + "/whatsapp/campaign";
  static SEND_CAMPAIGN_USING_JSON_BASE_URL: string = URLConstants.G_360_BASE_URL + "/v2/whatsapp/campaign";

}
